import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionDoctorFullNameService} from './doctor-full-name/search-engine-condition-doctor-full-name.service';
import {SearchEngineConditionDurationService} from './duration/search-engine-condition-duration.service';
import {SearchEngineConditionNumberOfSessionsService} from './number-of-sessions/search-engine-condition-number-of-sessions.service';
import {SearchEngineConditionStayAgeService} from './age/search-engine-condition-stay-age.service';
import {SearchEngineConditionNewBornWeightService} from './new-born-weight/search-engine-condition-new-born-weight.service';
import {SearchEngineConditionGestationalAgeService} from './age/search-engine-condition-gestational-age.service';
import {SearchEngineConditionIpDmsService} from './ip-dms/search-engine-condition-ip-dms.service';
import {SearchEngineConditionGhmService} from './ghm-gme/search-engine-condition-ghm.service';
import {SearchEngineConditionGmeService} from './ghm-gme/search-engine-condition-gme.service';
import {SearchEngineConditionGmtService} from './ghs-gmt/search-engine-condition-gmt.service';
import {SearchEngineConditionGhsService} from './ghs-gmt/search-engine-condition-ghs.service';
import {SearchEngineConditionGhmSeverityService} from './ghm-gme-severity/search-engine-condition-ghm-severity.service';
import {TranslationHelperService} from '../../../core/services/translation.helper.service';
import {SearchEngineConditionGmeSeverityService} from './ghm-gme-severity/search-engine-condition-gme-severity.service';
import {SearchEngineConditionIgs2Service} from './igs2/search-engine-condition-igs2.service';
import {SearchEngineConditionCodificationTypeService} from './codification-type/search-engine-condition-codification-type.service';
import {SearchEngineConditionQueryService} from './query/search-engine-condition-query.service';
import {DocumentTypeService} from '../../document-type/document-type.service';
import {SearchEngineConditionDocumentTypeService} from './document-type/search-engine-condition-document-type.service';
import {SearchEngineConditionStayIdService} from './stay-id/search-engine-condition-stay-id.service';
import {SearchEngineConditionCodificationStatusService} from './codification-status/search-engine-condition-codification-status.service';
import {StateService} from '@uirouter/core';
import {ConfigurationService} from '../../configuration/configuration.service';
import {SearchEngineConditionPerPageService} from './per-page/search-engine-condition-per-page.service';
import {SearchEngineConditionIsErasService} from './is-eras/search-engine-condition-is-eras.service';
import {SearchEngineConditionIsReadyService} from './is-ready/search-engine-condition-is-ready.service';
import {SearchEngineConditionHasDocumentOverlapRiskService} from './has-document-overlap-risk/search-engine-condition-has-document-overlap-risk.service';
import {SearchEngineConditionHasDocumentEssentialService} from './has-document-essential/search-engine-condition-has-document-essential.service';
import {SearchEngineConditionIsBilledService} from './is-billed/search-engine-condition-is-billed.service';
import {SearchEngineConditionSurgeryDateService} from './surgery-date/search-engine-condition-surgery-date.service';
import {SearchEngineConditionHospitalizationTypeService} from './hospitalization-type/search-engine-condition-hospitalization-type.service';
import {SearchEngineConditionDependencyService} from './dependency/search-engine-condition-dependency.service';
import {SearchEngineConditionStartDateService} from './date/search-engine-condition-start-date.service';
import {SearchEngineConditionEndDateService} from './date/search-engine-condition-end-date.service';
import {SearchEngineConditionLastMenstruationDateService} from './date/search-engine-condition-last-menstruation-date.service';
import {SearchEngineConditionGenderService} from './gender/search-engine-condition-gender.service';
import {SearchEngineConditionPresenceDaysService} from './presence-days/search-engine-condition-presence-days.service';
import {SearchEngineConditionHealthMovementExitService} from './health-movement/search-engine-condition-health-movement-exit.service';
import {SearchEngineConditionHealthMovementEntranceService} from './health-movement/search-engine-condition-health-movement-entrance.service';
import {SearchEngineConditionMedicalUnitService} from './medical-unit/search-engine-condition-medical-unit.service';
import {SearchEngineConditionQueryStructuredService} from './query-structured/search-engine-condition-query-structured.service';
import {DocumentTypeListService} from '../../document-type/list/document-type-list.service';
import {SearchEngineConditionValuationService} from './valuation/search-engine-condition-valuation.service';
import {SearchEngineConditionDiagnosticMissingService} from './diagnostic-missing/search-engine-condition-diagnostic-missing.service';
import {SearchEngineConditionDiagnosticSlugService} from './diagnostic-slug/search-engine-condition-diagnostic-slug.service';
import {SearchEngineConditionActSlugCCAMService} from './act-slug/search-engine-condition-act-slug-CCAM.service';
import {SearchEngineConditionActSlugCSARRService} from './act-slug/search-engine-condition-act-slug-CSARR.service';
import {SearchEngineConditionActMissingSlugCCAMService} from './act-missing-slug/search-engine-condition-act-missing-slug-CCAM.service';
import {SearchEngineConditionActMissingSlugCSARRService} from './act-missing-slug/search-engine-condition-act-missing-slug-CSARR.service';
import {SearchEngineConditionDpPredictiveSilverService} from './dp/predictive/gold-sliver/search-engine-condition-dp-predictive-silver.service';
import {SearchEngineConditionDpPredictiveGoldService} from './dp/predictive/gold-sliver/search-engine-condition-dp-predictive-gold.service';
import {SearchEngineConditionIppService} from './ipp/search-engine-condition-ipp.service';
import {SearchEngineConditionCurrentAgeService} from './age/search-engine-condition-current-age.service';
import {SearchEngineConditionBirthDateService} from './birth-date/search-engine-condition-birth-date.service';
import {SearchEngineConditionNameService} from './name/search-engine-condition-name.service';
import {SearchEngineConditionNumberOfStaysService} from './number-of-stays/search-engine-condition-number-of-stays.service';
import {SearchEngineConditionScaleService} from './scale/search-engine-condition-scale.service';
import {SearchEngineConditionCollectionService} from './collection/search-engine-condition-collection.service';
import {SearchEngineConditionFilterSearchTypeService} from './filter-search-type/search-engine-condition-filter-search-type.service';
import {SearchEngineConditionCodifyService} from './codify/search-engine-condition-codify.service';
import {SearchEngineConditionValidityFrameService} from './date/search-engine-condition-validity-frame.service';
import {FilterSearchService} from '../../filter-search/shared/filter-search.service';
import {SearchEngineConditionStayLengthService} from './stay-length/search-engine-condition-stay-length.service';
import {SearchEngineConditionPatientIppService} from './patient-ipp/search-engine-condition-patient-ipp.service';
import {SearchEngineConditionStayTypeService} from './stay-type/search-engine-condition-stay-type.service';
import {SearchEngineConditionEstablishmentService} from './establishment/search-engine-condition-establishment.service';
import {DiagnosticSlugHelperService} from './diagnostic-slug/diagnostic-slug-helper.service';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {SearchEngineConditionPredictionSourceService} from './prediction-source/search-engine-condition-prediction-source.service';
import {SearchEngineConditionUserAssignService} from './userId/search-engine-condition-user-assign.service';
import {SearchEngineConditionRevaluationService} from './revaluation/search-engine-condition-revaluation.service';
import {SearchEngineConditionDatasetElementCountService} from './dataset-element-count/search-engine-condition-dataset-element-count.service';
import {SearchEngineConditions} from '../enums/search-engine-conditions';
import {SearchEngineConditionQualifactService} from './qualifact/search-engine-condition-qualifact.service';
import {SearchEngineConditionQualifactSmrService} from './qualifactSmr/search-engine-condition-qualifactSmr.service';
import {SearchEngineConditionCodificationLabelPresenceService} from './codification-label-presence/search-engine-condition-codification-label-presence.service';
import {SearchEngineConditionPredictionDiagnosticSimplifiedScoreService} from './prediction/search-engine-condition-prediction-diagnostic-simplified-score.service';


@Injectable({
    providedIn: 'root'
})
export class SearchEngineConditionFactoryService {
    constructor(public $state: StateService,
                private _translateService: TranslateService,
                private _translationHelperService: TranslationHelperService,
                private _configurationService: ConfigurationService,
                private _documentTypeService: DocumentTypeService,
                private _documentTypeListService: DocumentTypeListService,
                private _filterSearchService: FilterSearchService,
                private _diagnosticSlugHelper: DiagnosticSlugHelperService,
                private _authenticationService: AuthenticationService,
    ) {
    }

    /**
     * Returns new instance of a search engine condition
     * @param slug service slug
     */
    getNewSearchConditionInstance(slug: string): any {
        switch (slug) {
            case 'actMissingSlug':
                return new SearchEngineConditionActMissingSlugCCAMService(this._translateService);
            case 'actMissingSlugCSARR':
                return new SearchEngineConditionActMissingSlugCSARRService(this._translateService);
            case 'actSlug':
                return new SearchEngineConditionActSlugCCAMService(this._translateService);
            case 'actSlugCSARR':
                return new SearchEngineConditionActSlugCSARRService(this._translateService);
            case 'aiCodedStatus':
                return new SearchEngineConditionCodificationTypeService(this._translateService, this._translationHelperService);
            case 'dependency':
                return new SearchEngineConditionDependencyService(this._translateService);
            case 'birthDate':
                return new SearchEngineConditionBirthDateService(this._translateService);
            case 'codify':
                return new SearchEngineConditionCodifyService(this._translateService);
            case 'codificationStatus':
                return new SearchEngineConditionCodificationStatusService(this.$state, this._translateService, this._configurationService);
            case 'collection':
                return new SearchEngineConditionCollectionService(this._translateService, this._configurationService, this._authenticationService);
            case 'currentAge':
                return new SearchEngineConditionCurrentAgeService(this._translateService);
            case 'diagnosisMissing':
                return new SearchEngineConditionDiagnosticMissingService(this.$state, this._translateService, this._translationHelperService, this._configurationService, this._diagnosticSlugHelper);
            case 'diagnosisPresent':
                return new SearchEngineConditionDiagnosticSlugService(this._translateService, this._translationHelperService, this._diagnosticSlugHelper);
            case 'healthWorkerFullName':
                return new SearchEngineConditionDoctorFullNameService(this._translateService);
            case 'documentContent':
                return new SearchEngineConditionQueryService(this._translateService);
            case 'documentTypePresence':
                return new SearchEngineConditionDocumentTypeService(this._translateService, this._documentTypeService);
            case 'dpPredictiveGold':
                return new SearchEngineConditionDpPredictiveGoldService(this._translateService);
            case 'dpPredictiveSilver':
                return new SearchEngineConditionDpPredictiveSilverService(this._translateService);
            case 'predictionDiagnosticSimplifiedScore':
                return new SearchEngineConditionPredictionDiagnosticSimplifiedScoreService(this._translateService);
            case 'duration':
                return new SearchEngineConditionDurationService(this._translateService);
            case 'endDate':
                return new SearchEngineConditionEndDateService(this._translateService, this._configurationService);
            case 'newBornWeight':
                return new SearchEngineConditionNewBornWeightService(this._translateService);
            case 'gestationalAge':
                return new SearchEngineConditionGestationalAgeService(this._translateService);
            case 'gender':
                return new SearchEngineConditionGenderService(this._translateService);
            case 'ghm':
                return new SearchEngineConditionGhmService(this._translateService);
            case 'ghmSeverity':
                return new SearchEngineConditionGhmSeverityService(this._translateService, this._translationHelperService);
            case 'gme':
                return new SearchEngineConditionGmeService(this._translateService);
            case 'gmeSeverity':
                return new SearchEngineConditionGmeSeverityService(this._translateService, this._translationHelperService);
            case 'gmt':
                return new SearchEngineConditionGmtService(this._translateService);
            case 'ghs':
                return new SearchEngineConditionGhsService(this._translateService);
            case 'ghmIpDMS':
                return new SearchEngineConditionIpDmsService(this._translateService);
            case 'hasDocumentEssential':
                return new SearchEngineConditionHasDocumentEssentialService(this._translateService);
            case 'hasDocumentOverlapRisk':
                return new SearchEngineConditionHasDocumentOverlapRiskService(this._translateService);
            case 'hospitalizationType':
                return new SearchEngineConditionHospitalizationTypeService(this._translateService);
            case 'igs2':
                return new SearchEngineConditionIgs2Service(this._translateService);
            case 'isBilled':
                return new SearchEngineConditionIsBilledService(this._translateService, this._configurationService);
            case 'isEras':
                return new SearchEngineConditionIsErasService(this._translateService);
            case 'isReady':
                return new SearchEngineConditionIsReadyService(this._translateService);
            case 'ipp':
                return new SearchEngineConditionIppService(this._translateService);
            case 'lastMenstruationDate':
                return new SearchEngineConditionLastMenstruationDateService(this._translateService);
            case 'medicalUnitId':
                return new SearchEngineConditionMedicalUnitService(this._translateService);
            case 'movementEntranceId':
                return new SearchEngineConditionHealthMovementEntranceService(this._translateService);
            case 'movementExitId':
                return new SearchEngineConditionHealthMovementExitService(this._translateService);
            case 'name':
                return new SearchEngineConditionNameService(this._translateService);
            case 'nbSession':
                return new SearchEngineConditionNumberOfSessionsService(this._translateService);
            case 'nbStays':
                return new SearchEngineConditionNumberOfStaysService(this._translateService);
            case 'patientIpp':
                return new SearchEngineConditionPatientIppService(this._translateService, this._configurationService);
            case 'perPage':
                return new SearchEngineConditionPerPageService(this._translateService);
            case 'predictionRevaluation':
                return new SearchEngineConditionValuationService(this._translateService);
            case 'presenceDays':
                return new SearchEngineConditionPresenceDaysService(this._translateService);
            case 'qualifactControl':
                return new SearchEngineConditionQualifactService(this._translateService,  this._documentTypeListService);
            case 'qualifactSmr':
                return new SearchEngineConditionQualifactSmrService(this._translateService,  this._documentTypeListService);
            case 'stayAge':
                return new SearchEngineConditionStayAgeService(this._translateService);
            case 'scale':
                return new SearchEngineConditionScaleService(this._translateService, this._configurationService);
            case 'stayId':
                return new SearchEngineConditionStayIdService(this._translateService, this._configurationService);
            case 'stayLength':
                return new SearchEngineConditionStayLengthService(this._translateService);
            case 'startDate':
                return new SearchEngineConditionStartDateService(this._translateService);
            case 'structuredData':
                return new SearchEngineConditionQueryStructuredService(this._translateService, this._documentTypeListService);
            case 'surgeryDate':
                return new SearchEngineConditionSurgeryDateService(this._translateService);
            case 'tagName':
                return new SearchEngineConditionFilterSearchTypeService(this._translateService);
            case 'validityFrame':
                return new SearchEngineConditionValidityFrameService(this._translateService, this._filterSearchService);
            case 'type':
                return new SearchEngineConditionStayTypeService(this._translateService);
            case SearchEngineConditions.Revaluation:
                return new SearchEngineConditionRevaluationService(this._translateService);
            case 'datasetElementCount':
                return new SearchEngineConditionDatasetElementCountService(this._translateService);
            case 'establishment':
                return new SearchEngineConditionEstablishmentService(this._translateService);
            case 'predictionSource':
                return new SearchEngineConditionPredictionSourceService(this._translateService);
            case 'userAssign':
                return new SearchEngineConditionUserAssignService(this._translateService);
            case 'codificationLabelPresence':
                return new SearchEngineConditionCodificationLabelPresenceService(this._translateService);
            default:
                return null;
        }
    }
}
