import {Component, Input, OnInit} from '@angular/core';
import {SimplifiedScoreEnum} from '../../../../diagnostic/diagnostic.model';
import {StateService} from '@uirouter/core';

@Component({
    selector: 'ct-stay-detail-act-prediction-list',
    templateUrl: './stay-detail-act-prediction-list.component.html',
    styleUrls: ['./stay-detail-act-prediction-list.component.scss', '../../diagnostic-prediction/list/stay-detail-diagnostic-prediction-list.component.scss']
})
export class StayDetailActPredictionListComponent implements OnInit {

    @Input() predictiveActs: any[];
    constructor(public $state: StateService) {
    }

    ngOnInit(): void {
    }

    filterActPrediction() {
        if (this.$state.current.name !== 'quality-control-near-automation') {
            return this.predictiveActs;
        }

        return this.predictiveActs.filter((predictiveAct: any) => {
            if (predictiveAct.act && predictiveAct.act.id &&
                [SimplifiedScoreEnum.GOLD, SimplifiedScoreEnum.SILVER].includes(predictiveAct.simplifiedScore)) {
                return true;
            }
        });
    }
}
