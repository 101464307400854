import {Component, Input, OnInit} from '@angular/core';
import {StateService} from '@uirouter/core';
import {ColorService} from '../../../../core/services/color.service';
import {TranslationHelperService} from '../../../../core/services/translation.helper.service';
import {CodificationLabelEnum} from '../../../stay/shared/codification-label-enum';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {PredictionDiagnosticSimplifiedScoreTypeEnum} from './search-engine-condition-prediction-diagnostic-simplified-score.service';
import {SimplifiedScoreEnum} from '../../../diagnostic/diagnostic.model';

@Component({
    selector: 'ct-search-engine-condition-prediction-diagnostic-simplified-score',
    templateUrl: './search-engine-condition-prediction-diagnostic-simplified-score.component.html',
    styleUrls: ['./search-engine-condition-prediction-diagnostic-simplified-score.component.scss']
})
export class SearchEngineConditionPredictionDiagnosticSimplifiedScoreComponent implements OnInit {

    constructor(
        public $state: StateService,
        public colorService: ColorService,
        private _translationHelperService: TranslationHelperService,
        private _configurationService: ConfigurationService
    ) {
    }

    @Input() condition: any;

    private _codificationLabels = {
        DP: '',
        DA: '',
        DR: '',
        MP: '',
        AE: '',
    };

    canDisplayDR: boolean;

    isMCOUseCase: boolean;
    isSSRUseCase: boolean;
    actualPage: string;
    isMultyPmsiType = false;
    PmsiValue: string;

    readonly CodificationLabelEnum = CodificationLabelEnum;
    readonly simplifiedScoreEnum = SimplifiedScoreEnum;
    readonly PredictionDiagnosticSimplifiedScoreTypeEnum = PredictionDiagnosticSimplifiedScoreTypeEnum;

    ngOnInit(): void {
        this.isSSRUseCase = this._configurationService.appTypeIs('SSR') || this._configurationService.appTypeIsMCOAndSSR();
        this.isMCOUseCase = this._configurationService.appTypeIs('MCO') || this._configurationService.appTypeIsMCOAndSSR();
        this.isMultyPmsiType = this.isMCOUseCase && this.isSSRUseCase;
        this.getActualPage();
        this._initCodificationLabels();
        this._initPmsiValue();
    }

    private getActualPage(): void {
        this.actualPage = this.$state.current.name;
        if (this.$state.params['fromFilterSearchList'] === 'true') {
            this.actualPage = 'FilterSearchList';
        }
    }

    private _initPmsiValue(): void {
        if (this.actualPage === 'stay-list-rehabilitation') {
            this.PmsiValue = 'SMR';
        } else if (this.actualPage === 'stay-list') {
            this.PmsiValue = 'MCO';
        } else if (this.actualPage === 'FilterSearchList' || this.actualPage === 'patient-detail') {
            this.PmsiValue = (this.isMultyPmsiType || this.isMCOUseCase) ? 'MCO' : 'SSR';
        }
    }

    private _initCodificationLabels(): void {
        if (this.isMCOUseCase) {
            this._initCodificationLabelsMCO();
        }
        if (this.isSSRUseCase) {
            this._initCodificationLabelsSSR();
        }
        this._codificationLabels.DA = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DA) || 'DA';
        this.canDisplayDR = this._translationHelperService.isFeatureAvailable('DR');
    }

    private _initCodificationLabelsMCO(): void {
        this._codificationLabels.DP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DP) || 'DP';
        this._codificationLabels.DR = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.DR) || 'DR';
    }

    private _initCodificationLabelsSSR(): void {
        this._codificationLabels.MP = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.MP) || 'MP';
        this._codificationLabels.AE = this._translationHelperService.getCodificationLabel(CodificationLabelEnum.AE) || 'AE';
    }

    public getScoreKey(value: number): string {
    return Object.keys(SimplifiedScoreEnum).find(key => SimplifiedScoreEnum[key as keyof typeof SimplifiedScoreEnum] === value) || 'UNKNOWN';
    }
}
