<div>
    <mat-checkbox (change)="updateColumnsToDisplay(true)"
                  *ngFor="let col of columns"
                  [(ngModel)]="colsToActivate[col.slug]"
                  [checked]="colsToActivate[col.slug]"
                  class="margin-left-10"
                  color="primary">
        <span>{{col.name}}</span>
    </mat-checkbox>
</div>
<div class="total-data">
    <p><b>{{'DATA_MONITORING.DOCUMENT_STAY_DETAIL.TOTAL_DATA_STRUCTURED' | translate}}</b>{{ rowsToDisplay.length }}</p>
</div>
<ngx-datatable
    #table
    *ngIf="columnsToDisplay.length > 0"
    [columnMode]="'force'"
    [footerHeight]="35"
    [headerHeight]="40"
    [limit]="limit"
    [messages]="tableMessages"
    [reorderable]="true"
    [rowClass]="getRowClass"
    [rowHeight]="'auto'"
    [rows]="rowsToDisplay"
    [scrollbarH]="true"
    class="material">
    <ngx-datatable-column
        *ngFor="let col of columnsToDisplay"
        [name]="col.name"
        [prop]="col.prop">
    </ngx-datatable-column>

    <ngx-datatable-footer>
        <ng-template let-curPage="curPage"
                     let-offset="offset"
                     let-pageSize="pageSize"
                     let-rowCount="rowCount"
                     let-selectedCount="selectedCount"
                     ngx-datatable-footer-template>
            <datatable-pager
                (change)="table.onFooterPage($event)"
                [count]="rowCount"
                [hidden]="!canDisplayPaginator()"
                [page]="curPage"
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [size]="pageSize">
            </datatable-pager>
        </ng-template>
    </ngx-datatable-footer>
</ngx-datatable>
