<div class="flex space-x-8 items-center">
    <!-- Separator -->
    <div class="bg-white/20 h-[30px] w-[1px]"></div>

    <div class="flex space-x-2 items-center">
        <mat-checkbox
            [(ngModel)]="isValidated"
            class="margin-right-10"
        >
            <span
                ngbTooltip="{{ 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DEPENDENCY_MISSING' | translate }}"
                tooltipClass="ngb-tooltip"
            >
                {{ 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DEPENDENCY_MISSING' | translate }}
            </span>
        </mat-checkbox>

        <mat-checkbox
            [(ngModel)]="condition?.service?.params.isValidated"
            class="margin-right-15"
        >
            <span
                ngbTooltip="{{ 'STAY.STATUS_VALID' | translate }}"
                tooltipClass="ngb-tooltip"
            >
                {{ 'STAY.STATUS_VALID' | translate }}
            </span>
        </mat-checkbox>
    </div>

    <!-- Separator -->
    <div class="bg-white/20 h-[30px] w-[1px]"></div>

    <div class="flex space-x-2 items-center">
        <mat-form-field class="width-190 margin-right-10">
            <mat-select [(ngModel)]="condition?.service?.params.type"
                        [disabled]="dependencyType?.length === 0"
                        [placeholder]="'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DEPENDENCY_LEVEL' | translate">
                <mat-option *ngFor="let el of dependencyType"
                            [value]="el.value">
                    {{ el.translation | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="width-190 margin-right-10">
            <mat-select #multipleSelect
                        [(ngModel)]="condition?.service?.params[paramKey]"
                        [disabled]="dependencyLevel?.length === 0"
                        [placeholder]="'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DEPENDENCY_LEVEL' | translate"
                        multiple>
                <mat-option *ngFor="let el of dependencyLevel"
                            [value]="el.id">
                    {{ el.level + " " }} {{ el.text | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
