import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SearchEngineConditionPredictionDiagnosticSimplifiedScoreComponent} from './search-engine-condition-prediction-diagnostic-simplified-score.component';
import {SimplifiedScoreEnum} from '../../../diagnostic/diagnostic.model';

export enum PredictionDiagnosticSimplifiedScoreTypeEnum {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    ASSOCIATED = 'associated'
}

@Injectable({
    providedIn: 'root'
})

export class SearchEngineConditionPredictionDiagnosticSimplifiedScoreService extends SearchEngineCondition {
    constructor(private _translateService: TranslateService) {
        super(['predictionDiagnosticSimplifiedScore'],
            _translateService.instant('DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_DIAGNOSTICS_SIMPLIFIED'),
            'predictionDiagnosticSimplifiedScore',
            SearchEngineConditionPredictionDiagnosticSimplifiedScoreComponent,
            true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {
            type: PredictionDiagnosticSimplifiedScoreTypeEnum.PRIMARY,
            minValue: SimplifiedScoreEnum.GOLD,
            operator: '>='
        };
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {type: this.params.type, minValue: this.params.minValue, operator: '>='};
    }

    convertToParamsFromApiParams(): void {
        this.params.type = this.apiParams.args.type;
        this.params.minValue = this.apiParams.args.minValue;
    }
}
