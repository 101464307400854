import {Component, OnInit} from '@angular/core';
import {CodificationLabelEnum} from '../../../stay/shared/codification-label-enum';
import {DependencyLevelEnum} from './search-engine-condition-dependency.service';

@Component({
    selector: 'ct-search-engine-condition-dependency',
    templateUrl: './search-engine-condition-dependency.component.html'
})
export class SearchEngineConditionDependencyComponent implements OnInit {
    condition: any;
    paramKey: string;
    placeholder: string;
    dependencyLevel: {id: number, text: string, level: DependencyLevelEnum}[];
    dependencyType: {key: CodificationLabelEnum , translation: string, value: string}[];

    constructor() { }

    ngOnInit(): void {
    }

    get isValidated(): boolean {
        return this.condition?.service?.params.operator === 'NAND';
    }

    set isValidated(value: boolean) {
        this.condition.service.params.operator = value ? 'NAND' : 'AND';
    }
}
