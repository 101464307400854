import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {StayListService} from '../../list/shared/stay-list.service';
import {DiagnosticDiagnosticTextApiService} from '../../../../core/api-services/diagnostic/diagnostic-text/diagnostic-diagnostic-text.api.service';
import {InnerHitsService} from '../../../../core/services/inner-hits.service';
import {ConfigurationService} from '../../../configuration/configuration.service';
import {BroadcastService} from '../../../../core/services/broadcast.service';
import { SearchEngineAdvancedService } from 'src/app/modules/search-engine/advanced/search-engine-advanced.service';
import {CriteriaTypes, QueryTypes} from '../../../search-engine/advanced/query-builder/enums/types';
import {StayDetailService} from '../stay-detail.service';
import * as _ from 'lodash-es';
import {ActApiService} from '../../../../core/api-services/act/act.api.service';
import {StateService} from '@uirouter/core';

export interface Tab {
    name: string;
    id: number;
    query: any;
    qDocumentTypeId: any[];
    healthDocumentId: number[];
    highlight: any;
    highlightList: any[];
    hasNoResult: boolean;
    init: boolean;
    qualifactControls?: any[];
}

@Injectable({
    providedIn: 'root'
})
export class StayDetailJustificationService {
    private _stayId: number;

    private _tabs: Tab[] = [];
    private _rumId: number;
    private _activeTabId: number;

    constructor(
        private _stayDetailService: StayDetailService,
        private _stayListService: StayListService,
        private _broadcastService: BroadcastService,
        private _diagnosticDiagnosticTextApiService: DiagnosticDiagnosticTextApiService,
        private _innerHitsService: InnerHitsService,
        private _translateService: TranslateService,
        private _configurationService: ConfigurationService,
        private _searchEngineAdvancedService: SearchEngineAdvancedService,
        private _actApiService: ActApiService,
        private _state: StateService,
    ) {
    }

    get tabs(): Tab[] {
        return this._tabs;
    }

    set tabs(value: Tab[]) {
        this._tabs = value;
    }

    get activeTabId(): number {
        return this._activeTabId;
    }

    set activeTabId(value: number) {
        this._activeTabId = value;
    }

    private _initTab(): Tab {
        return {
            name: '',
            id: null,
            query: '',
            qDocumentTypeId: [],
            healthDocumentId: [],
            highlight: [],
            highlightList: [],
            hasNoResult: false,
            init: false
        };
    }

    private _getHighlightedText(tab: Tab) {
        const list = [];
        if (tab &&
            tab.highlight) {
            tab.highlight.forEach(obj => {
                if (obj.documentContent) {
                    obj.documentContent.forEach(el => {
                        if (el.highlight &&
                            el.highlight.content) {
                            el.highlight.content.forEach(line => {
                                const regex = /<em>(.*?)<\/em>/g;
                                const matches = line.match(regex);
                                if (matches) {
                                    matches.forEach((value: string) => {
                                        list.push({
                                            structuredData: false,
                                            text: value.replace(/<\/?em>/g, ''),
                                            score: 1
                                        });
                                    });
                                }
                            });
                        }

                    });
                }

                if (obj.structuredData) {
                    list.push({
                        structuredData: true,
                        documentTypeId: obj.documentTypeId
                    });
                }
            });
        }
        return list;
    }

    /**
     * If the diagnosticText type is AI and its content doesn't end with " or ~X we add an *
     * named diagnosticText but also work for act
     * (X being a number)
     * @param diagnosticText
     * @private
     */
    private _getContentForJustification(diagnosticText: any) {
        if (diagnosticText &&
            diagnosticText.content &&
            diagnosticText.type) {
            const regex = /.+("|~[0-9]+)$/g;
            return diagnosticText.type.name === 'AI' && !diagnosticText.content.match(regex) ?
                diagnosticText.content + '*' : diagnosticText.content;
        }
        return '';
    }

    /**
     * Load highlight for query as diagnostic slug
     * @param tab
     * @param diagnosticId
     * @param withChildren
     * @param stayData
     * @private
     */
    private async _loadDiagnosticTextByDiagnosticId(tab: Tab, diagnosticId: number, withChildren: boolean, stayData: any) {
        if (tab.hasNoResult || tab.query) {
            tab.hasNoResult = true;
            return null;
        } else {
            try {
                const data = await this._diagnosticDiagnosticTextApiService
                    .getAll(diagnosticId)
                    .toPromise();
                const tmpText = [];
                const minScore = this._configurationService.getConfigurationContent('front', 'diagnostic.justification.minScore');
                if (data) {
                    data.forEach(el => {
                        if (el.score >= minScore || el.type === 'rule') {
                            const content = this._getContentForJustification(el);
                            tmpText.push(content);
                            tab.highlightList.push({
                                structuredData: false,
                                text: content,
                                score: el.score
                            });
                        }
                    });
                }

                if (tmpText.length === 0) {
                    tab.hasNoResult = true;
                }
                tab.query = this.getDocumentContentQuery(stayData.stayId, tmpText.join(' OR '));
            } catch (e) {
                tab.query = '';
                throw e;
            }
        }
    }

    private async _loadActTextByDiagnosticId(tab: Tab, actId: number, withChildren: boolean, stayData: any) {
        if (tab.hasNoResult || tab.query) {
            tab.hasNoResult = true;
            return null;
        } else {
            try {
                const data = await this._actApiService
                    .getActJustification(actId)
                    .toPromise();
                const tmpText = [];
                // same config with act and diag justification
                const minScore = this._configurationService.getConfigurationContent('front', 'diagnostic.justification.minScore');
                if (data) {
                    data.forEach(el => {
                        if (el.score >= minScore || el.type === 'rule') {
                            const content = this._getContentForJustification(el);
                            tmpText.push(content);
                            tab.highlightList.push({
                                structuredData: false,
                                text: content,
                                score: el.score
                            });
                        }
                    });
                }

                if (tmpText.length === 0) {
                    tab.hasNoResult = true;
                }
                tab.query = this.getDocumentContentQuery(stayData.stayId, tmpText.join(' OR '));
            } catch (e) {
                tab.query = '';
                throw e;
            }
        }
    }

    public getDocumentContentQuery(stayId: string, contents: string): any {
        return {
            'type': 'bool',
            'args': {
                'operator': 'AND',
                'criteria': [
                    {
                        'type': 'bool',
                        'args': {
                            'operator': 'AND',
                            'criteria': [
                                {
                                    'type': 'stayId',
                                    'args': {
                                        'values': [
                                            stayId
                                        ]
                                    }
                                },
                                {
                                    'type': 'documentContent',
                                    'args': {
                                        'values': [
                                            {
                                                'qMust': contents,
                                                'qNot': null,
                                                'qOpt': null,
                                                'documentTypeIds': [],
                                                'ignoreNegation': false
                                            }
                                        ]
                                    }
                                }
                            ]
                        }
                    }
                ]
            }
        };
    }

    private async _loadDataSetElementHighlight(tab: Tab, data?: any) {
        try {
            // Clone the object, to avoid side effects with the global
            // FilterSearch stored in sessionStorage.
            const params = _.cloneDeep(this._getExtraParams(tab, data));
            const whiteListCriteria = [
                CriteriaTypes.StayId,
                CriteriaTypes.DocumentContent,
                CriteriaTypes.StructuredData,
                CriteriaTypes.QualifactControl,
                CriteriaTypes.QualifactSmr,
            ];
            params.query = this._searchEngineAdvancedService.removeFilterSearchQueryNotInWhiteList(whiteListCriteria, params?.query);
            params.query = this._searchEngineAdvancedService.removeFilterSearchQueryForbidenWord(params?.query);

            let boolCriteria = params?.query?.args?.criteria.filter(criteria => criteria.type === QueryTypes.Bool)[0];
            const targetCriteria = boolCriteria?.args?.criteria;
            const qualifactControlFilters = [];
            const qualifactConditions = ['qualifactControl', 'qualifactSmr'];
            const hasQualifactControlsFilter = qualifactConditions.some(condition => {
                if (this._searchEngineAdvancedService.filterSearchQueryHasCondition(condition, targetCriteria)) {
                    qualifactControlFilters.push(
                        ...this._searchEngineAdvancedService.filterSearchQueryGetAllCondition(condition, targetCriteria)
                    );
                    return true;
                }
                return false;
            });
            if (this._stayDetailService.hasDiagnosisMissingSlug(boolCriteria)) {
                boolCriteria = this._searchEngineAdvancedService.removeFilterSearchQueryBySlug('diagnosisMissing', boolCriteria);
            }
            const include = ['subStays', 'diagnostics', 'establishment'];
            if (hasQualifactControlsFilter) {
                include.push('qualifactControls');
            }

            const res = await this._stayListService
                .loadDataSetElements(1, 1, params, include);
            const tmpHighlight: any[] = [];

            if (res.data && res.data.length > 0) {
                const innerHits = res.data[0].innerHits;
                const qualifactControls = res.data[0]?.data?.dataSetContent?.qualifactControls ?? [];
                if (innerHits?.documentContent?.length > 0 || innerHits?.structuredData?.length > 0) {
                    tab.hasNoResult = false;
                    this._innerHitsService.groupDocumentContent(tmpHighlight, innerHits);
                    this._innerHitsService.groupStructuredData(tmpHighlight, innerHits);
                } else if (hasQualifactControlsFilter && qualifactControls.length) {
                    qualifactControlFilters.forEach(qcFilter => {
                        const argsFilter = qcFilter.args;
                        const diagnosesString = argsFilter?.diagnoses?.length ? JSON.stringify(argsFilter.diagnoses) : null;
                        const actsString = argsFilter?.acts?.length ? JSON.stringify(argsFilter.acts) : null;

                        const qualifactControlExisted = qualifactControls.find(
                            qc => {
                                const codeInFilter = argsFilter.codes.length ? argsFilter.codes.includes(qc.code) : false;
                                return codeInFilter &&
                                    (!diagnosesString || JSON.stringify(qc.diagnoses) === diagnosesString) &&
                                    (!actsString || JSON.stringify(qc.acts) === actsString);
                            }
                        );
                        if (qualifactControlExisted) {
                            tab.qualifactControls.push({
                                ...qualifactControlExisted,
                            });
                        }

                    });
                } else {
                    tab.hasNoResult = true;
                }
            } else {
                tab.hasNoResult = true;
            }
            tab.init = false;

             // tab.query = '';
            tab.qDocumentTypeId = [];
            this._updateTabQuery(tab);
            // Merge arrays into one
            tab.healthDocumentId = [].concat(...tmpHighlight.map(el => el.healthDocumentId));
            tab.highlight = tmpHighlight;
            tab.highlightList = this._getHighlightedText(tab);

            return tab;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private _updateTabQuery(tab: Tab) {
        const documentContentQuery = this._searchEngineAdvancedService.filterSearchQueryGetCondition('documentContent', tab.query?.args?.criteria);

        if (documentContentQuery) {
                const tmpQuery = documentContentQuery?.args?.values[0];
                if (tmpQuery?.qMust) {
                    tab.query = tmpQuery.qMust;
                }
                if (tmpQuery?.qOpt) {
                    tab.query = tmpQuery.qOpt;
                }
        }
    }

    private _addTab(tab: Tab) {
        this._tabs.push(tab);
        this._activeTabId = tab.id;
    }

    clearData() {
        this._tabs = [];
        this._stayId = null;
        this._rumId = null;
        this._activeTabId = null;
    }

    private _clearOnlyFirstTab() {
        const firstTabIndex = this.tabs.findIndex(tab => tab.id === -1);
        if (firstTabIndex !== -1) {
            this.tabs.splice(firstTabIndex, 1);
        }
    }

    deleteTabByName(name: string, selectFirstTab: boolean) {
        const tabIndex = this.tabs.findIndex(tab => tab.name === name);
        if (tabIndex !== -1) {
            this._broadcastService.send('tabList::deleteTab', {id: this.tabs[tabIndex].id});
            this.tabs.splice(tabIndex, 1);
        }
        if (selectFirstTab) {
            this.activeTabId = -1;
        }
    }
    public getQueryDiagnosisMissingIndex(query: any) {
        if (query?.args?.criteria[this._searchEngineAdvancedService.getQueryConditionIndex(query, 'bool')]?.args?.criteria) {
            return query.args.criteria[this._searchEngineAdvancedService.getQueryConditionIndex(query, 'bool')].args.criteria.findIndex(x => x.type === 'diagnosisMissing');
        }
        return -1;
    }
    private _getExtraParams(tab: any, data?: any) {
        const query = data?.query ?? tab.query;
        if (!this._searchEngineAdvancedService.filterSearchQueryHasCondition('stayId', query?.args?.criteria)) {
            query?.args?.criteria.push({
                'type': 'stayId',
                'args': {
                    'values': [
                        data?.stayId ?? this._stayId
                    ]
                }
            });
        }
        return {
            perPage: 1,
            query: query
        };
    }

    /**
     * Init "Recherche" tab
     * @param data
     * @param reloadOnlyFirstTab if true then we don't clear all data but only "Recherche tab"
     */
    async init(data: any, reloadOnlyFirstTab: boolean = false, tagName = null): Promise<any> {
        try {
            // Clear data because otherwise if we don't empty app cache
            // data is not empty
            if (reloadOnlyFirstTab) {
                this._clearOnlyFirstTab();
            } else {
                this.clearData();
            }

            this._stayId = data.stayId;
            this._rumId = data.rumId;
            this.activeTabId = -1;
            const tmpTab: Tab = {
                name: this._translateService.instant('DATA_SET.CODIFICATION_ELEMENT.JUSTIFICATION.SEARCH'),
                id: -1,
                query: data.query,
                qDocumentTypeId: [],
                healthDocumentId: [],
                highlight: [],
                highlightList: [],
                hasNoResult: false,
                init: true,
                qualifactControls: []
            };

            this._tabs.push(tmpTab);

            if (tmpTab.query) {
                return await this.reloadTab(tmpTab);
            } else {
                tmpTab.hasNoResult = true;
                return {};
            }
        } catch (e) {
            throw e;
        }
    }

    getTabById(id: number) {
        const tabToFind = this._tabs.find(tab => tab.id === id);
        return tabToFind ? tabToFind : null;
    }

    isTabActiveByName(name: string) {
        const tabToFind = this._tabs.find(tab => tab.name === name);
        if (tabToFind) {
            return this.activeTabId === tabToFind.id;
        }
        return false;
    }

    async reloadTab(tab: Tab) {
        try {
            return await this._loadDataSetElementHighlight(tab);
        } catch (e) {
            throw e;
        }
    }

    /**
     * Load justification for a specific diagnostic and add a tab
     * @param diagnosticId
     * @param diagnosticSlug
     * @param withChildren
     * @param stayData
     * @param isAct if is act
     */
    async addTabByDiagnosticOrActId(diagnosticId: number, diagnosticSlug: string, withChildren: boolean, stayData?: any, isAct?: false) {
        const tab = this.getTabById(diagnosticId);
        if (stayData && tab) {
            this._activeTabId = diagnosticId;
            return tab;
        } else {
            const tmpTab = this._initTab();
            tmpTab.name = diagnosticSlug;
            tmpTab.id = diagnosticId;
            try {
                // Build query with diagnostic data
                if (isAct) {
                    await this._loadActTextByDiagnosticId(tmpTab, diagnosticId, withChildren, stayData);
                } else {
                    await this._loadDiagnosticTextByDiagnosticId(tmpTab, diagnosticId, withChildren, stayData);
                }
                if (tmpTab.query) {
                    // Get dataSetElementHighlight with built query
                    await this._loadDataSetElementHighlight(tmpTab, stayData);
                    this._addTab(tmpTab);
                    return tmpTab;
                } else {
                    this._addTab(tmpTab);
                    return tmpTab;
                }
            } catch (e) {
                throw e;
            }
        }
    }
}
