import {Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {BroadcastService} from '../../../core/services/broadcast.service';
import {AppLogService} from '../../../core/app-log/app-log.service';
import {ConfigurationService} from '../../configuration/configuration.service';
import {DataHelperService} from '../../../core/services/data.helper.service';
import * as _ from 'lodash-es';
import {StayDetailService} from './stay-detail.service';
import {StateService} from '@uirouter/core';
import {LayoutService} from '../../layout/layout.service';
import {DataSetCodification} from '../shared/data-set-codification.model';
import {StayListService} from '../list/shared/stay-list.service';
import {DataSetElement} from '../shared/data-set-element.model';
import {DataSetElementService} from '../shared/data-set-element.service';
import {DiagnosticService} from '../../diagnostic/diagnostic.service';
import {displayAnimations} from '../../../shared/animations/display.animation';
import {StayDetailCodificationService} from './codification/stay-detail-codification.service';
import {TranslateService} from '@ngx-translate/core';
import {SearchEngineAdvancedService} from '../../search-engine/advanced/search-engine-advanced.service';
import {StayDetailCodificationDiagnosticService} from './codification/diagnostic/stay-detail-codification-diagnostic.service';
import {SnackBarService} from '../../../core/services/snack-bar.service';
import moment from 'moment';
import {TranslationHelperService} from '../../../core/services/translation.helper.service';
import {MatMenuTrigger} from '@angular/material/menu';
import {AuthenticationService} from '../../../core/authentication/authentication.service';
import {SmrTabs} from './period-select/period-select.component';
import {CodificationLabelsModel} from '../shared/codification-labels.model';

@Component({
    selector: 'ct-stay-detail',
    templateUrl: './stay-detail.component.html',
    styleUrls: ['./stay-detail.component.scss'],
    animations: displayAnimations
})
export class StayDetailComponent implements OnInit, OnDestroy {

    constructor(
        public $state: StateService,
        private _translateService: TranslateService,
        private _layoutService: LayoutService,
        private _dataSetElementService: DataSetElementService,
        private _diagnosticService: DiagnosticService,
        private _stayListService: StayListService,
        private _searchEngineAdvancedService: SearchEngineAdvancedService,
        private _stayDetailService: StayDetailService,
        private _stayDetailCodificationService: StayDetailCodificationService,
        private _broadcastService: BroadcastService,
        private _logService: AppLogService,
        private _configurationService: ConfigurationService,
        private _stayDetailCodificationDiagnosticService: StayDetailCodificationDiagnosticService,
        private _snackbarService: SnackBarService,
        private _authenticationService: AuthenticationService,
        private _translationHelperService: TranslationHelperService,
    ) {
    }

    get isDialog() {
        return this.$state.params.isDialog === 'true';
    }
    @ViewChild('filterSearchExpansionPanel') filterSearchExpansionPanel: ElementRef;
    @ViewChild('impreciseDiagnosticExpansionPanel') impreciseDiagnosticExpansionPanel: ElementRef;
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;

    private _dataSetId: number;
    private _codificationId: number;
    private _dataSetElementId: number;
    private _dataSetElementParentId: number;
    private _subscriptions: Subscription[] = [];
    private _timeoutForDashboardEvent: any;
    private _intervalForDashboardEvent: any;
    private _shouldStopSendingEvent: boolean;
    private _filtersSearch: any[];
    private _fabButtonsOver: boolean;
    private _timeoutForFabButtons: any;
    private _expansionPanelAvailableInterval;

    isActive = false;
    notFound = false;
    showFabButtons = false;
    hasScrolled: boolean;
    dataSetElement: DataSetElement;
    dataSetElementParent: DataSetElement;
    dataSetCodification: DataSetCodification;
    codificationLabels: CodificationLabelsModel;
    canDisplayStayPredictions: boolean;
    canDisplayImpreciseDiagnostic: boolean;
    leftSideDisplayState = 'initial';
    codificationHistoryDisplayState = 'none';
    commentDisplayState = 'none';
    isTypeRehabilitation: boolean;
    isNearAutomationQualityControl: boolean;
    stayDetailInfoHeaderTitle: string;
    isTypeExternal: boolean;
    diagnosticHistory: any[] = [];
    impreciseDiagnostics: any[] = [];
    docNb = 0;
    isLoading = false;
    canDisplayRUM: boolean;
    activeTabId: number;
    qualifactControls: any[];

    public SSRWeeks: any[] = [];
    public dependencies = [];
    public selectedSSRWeek: any = {};

    public errors: any = null;

    @Output() loadingAction: EventEmitter<string> = new EventEmitter<string>();

    protected readonly SmrTabs = SmrTabs;

    @HostListener('window:beforeunload', ['$event'])
    unloadNotification($event: any): void {
        const hasUpdatedCodification = sessionStorage.getItem('hasUpdatedCodification') === 'true';

        if (hasUpdatedCodification) {
            $event.returnValue = false;
        }
    }

    async ngOnInit() {
        try {
            this._loadingAction('start');
            this._handleSessionStorage();
            this._initData();

            await this._reload();

            if (this.isTypeRehabilitation) {
                this._initDependencies();
                this._initSSRWeeks();
            }

            this._setIntervalForDashboardEvent();
            this._sendLogToLogStash();
            this._subscribeToBroadcast();

            this._initFeatures();
        } catch (e) {
            this.errors = e;

            this._layoutService.stopLoading();
        }
    }

    goBack(): void {
        window.history.back();
    }

    private _initFeatures() {
        this.canDisplayRUM = this._translationHelperService.isFeatureAvailable('RUM') && !this.isTypeRehabilitation;
    }

    private _initDependencies(): void {
        if (!this.dataSetElement?.dataSetContent?.healthStayAdditional) {
            return;
        }

        const getDependencyTextFromValue = this._stayDetailCodificationService.getDependencyTranslationKeyFromId;

        if (this.dataSetElement.dataSetContent?.validatedDependencyCollection?.length) {
            this.dependencies = [
                {key: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.BEHAVIOUR', text: this._translateService.instant(getDependencyTextFromValue(this.dataSetElement.dataSetContent.validatedDependencyCollection[0].behaviourDependency))},
                {key: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.CONTINENCE', text: this._translateService.instant(getDependencyTextFromValue(this.dataSetElement.dataSetContent.validatedDependencyCollection[0].continenceDependency))},
                {key: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.MOVING', text: this._translateService.instant(getDependencyTextFromValue(this.dataSetElement.dataSetContent.validatedDependencyCollection[0].movingDependency))},
                {key: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DRESSING', text: this._translateService.instant(getDependencyTextFromValue(this.dataSetElement.dataSetContent.validatedDependencyCollection[0].dressingOrToiletingDependency))},
                {key: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.EATING', text: this._translateService.instant(getDependencyTextFromValue(this.dataSetElement.dataSetContent.validatedDependencyCollection[0].eatingDependency))},
                {key: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.RELATIONSHIP', text: this._translateService.instant(getDependencyTextFromValue(this.dataSetElement.dataSetContent.validatedDependencyCollection[0].relationshipDependency))},
            ];
        }

    }

    private _initSSRWeeks(): void {
        if (!this.dataSetElementParent?.children?.length) {
            return;
        }

        this.dataSetElementParent.children.forEach((child, index, array) => {
            if (index === 0) {
                // We don't want to display the first week because it's already displayed
                // in the first tab
                return;
            }

            const momentStartDate = moment(child.dataSetContent.startDate);
            const momentEndDate = moment(child.dataSetContent.endDate);

            this.SSRWeeks.push({
                isFirstChild: index === 1,
                isLastChild: index === array.length - 1,
                value: child.id,
                label: `${momentStartDate.format('DD')} ${momentStartDate.format('MMM')} ${momentStartDate.format('YYYY')} - ${momentEndDate.format('DD')} ${momentEndDate.format('MMM')} ${momentEndDate.format('YYYY')}`
            });
        });

        this.selectedSSRWeek = this.SSRWeeks.find(el => el.value === this.dataSetElement.id) || {};
    }

    /**
     * We show the fab button on mousemove and
     * hide it after 1s
     */
    @HostListener('mousemove', ['$event'])
    onMouseMove() {
        this._handleTimeoutForFabButtons();
        this._handleTimeoutForDashboardEvent();
    }

    @HostListener('scroll', ['$event'])
    onRightSideScroll(event) {
        if (event && event.target) {
            this.hasScrolled = event.target.scrollTop > 40;
        }
    }

    private _subscribeToBroadcast() {
        const sub = this._broadcastService.broadcastData
            .subscribe(res => {
                switch (res.message) {
                    case 'stayDetailCodification::goToSSRWeek':
                        if (res.data &&
                            res.data.id !== 3 &&
                            this.dataSetElementParent &&
                            this.dataSetElementParent.children &&
                            this.dataSetElementParent.children.length) {
                            if (res.data.id === 1) {
                                this.goToDataSetElement(this.dataSetElementParent.children[0].id);
                            } else {
                                this.goToDataSetElement(this.dataSetElementParent.children[1].id);
                            }
                        }
                        break;
                    case 'stayDetailCodification::goToDataSetElement':
                        if (res.data) {
                            this.goToDataSetElement(res.data.id);
                        }
                        break;
                    case 'stayDetail::editDataSetElementDates':
                        this._loadDataSetElementParent();
                        break;
                    case 'stayDetailCodificationNearAutomation::cancel':
                        this._handleCancelNearAutomation();
                        break;
                    case 'act::addAct':
                        if (res.data &&
                            res.data.success) {
                            this._reload();
                        }
                        break;
                    case 'act::deleteActs':
                    case 'diagnostic::removeDiagnostic':
                    case 'diagnostic::editDiagnostic':
                    case 'diagnostic::addDiagnostic':
                        if (res.data?.success) {
                            this._handleEditCodification(res.data.reload);
                        }
                        break;
                    case 'diagnosticPredictionListMenu::changeOption':
                        // If we switch to group by parent from prediction list menu
                        // we reload predictions
                        this._loadPredictiveDiagnostics(true, res.data?.switchGroupByParent);
                        break;
                    case 'stay::updateGold':
                    case 'stay::grouping':
                        this._loadPredictiveDiagnostics(res.data.reload);
                        this._updateStayPrice();
                        break;
                    case 'stayDetailDialog::scroll':
                        // To display or not the fab buttons
                        this.hasScrolled = res.data.scrollTop > 40;
                        break;
                    case 'stayDetailInfo::loadPatientStayHistoryDone':
                        if (res.data &&
                            res.data.diagnosticHistory) {
                            this.diagnosticHistory = Object.values(res.data.diagnosticHistory);
                        }
                        break;
                    case  'stayDetailImpreciseDiagnostic::setImpreciseDiagnostics':
                        this.impreciseDiagnostics = res.data.impreciseDiagnostics;
                        break;
                    case  'stayDetailDocument::documentsNumber':
                        this.docNb = res.data.docNb;
                        break;
                }
            });

        this._subscriptions.push(sub);
    }

    /**
     * Reload page when change tab
     * @param dataSetElementId
     */
    goToDataSetElement(dataSetElementId: number) {
        // If we are on classic stay detail page
        if (this.$state.params.isDialog !== 'true') {
            const params = {
                ...this.$state.params,
                dataSetElementId,
                access: 'dataSetElementId'
            };

            if (
                this.dataSetElementParent
                && this.dataSetElementParent.children
                && this.dataSetElementParent.children.length
            ) {
                params['isFirstSSRWeek'] = dataSetElementId === this.dataSetElementParent.children[0].id
                    ? 'true'
                    : 'false';
            }

            this.$state.go(this.$state.current.name, params);

            return;
        }

        // If we are on stay detail dialog in prediction analysis page
        // we send message to close and open dialog to switch dataSetElement
        this._broadcastService.send('stayDetailInfo::reload', {dataSetElementId});
    }

    private _initConfig() {
        this.canDisplayImpreciseDiagnostic = this._configurationService.getConfigurationContent('front', 'menu.canDisplayQualityControlImpreciseDiagnosticModule');
    }

    private _initData() {
        this._initConfig();
        this._dataSetId = this.$state.params.dataSetId;
        this._codificationId = this.$state.params.codificationId;
        this._dataSetElementId = this.$state.params.dataSetElementId;
        this._dataSetElementParentId = this.$state.params.parentId;
        this.isNearAutomationQualityControl = this.$state.params.fromQualityControlNearAutomation === 'true' && this.$state.params.isNearAutomationDone !== 'true' && this.$state.params.fromStayDetail !== 'true';

        if (this._searchEngineAdvancedService.filterSearchQuery) {
            return;
        }

        const filterSearch = sessionStorage.getItem('filterSearch');

        if (filterSearch) {
            this._searchEngineAdvancedService.filterSearchQuery = JSON.parse(filterSearch) || null;
        }
    }

    private _initStayDetailInfoHeader(): void {
        if (!this.dataSetElement?.dataSetContent) {
            this.stayDetailInfoHeaderTitle = '';

            return;
        }

        const endOfString = this.dataSetElement.dataSetContent?.stayId || this.dataSetElement.dataSetContent?.stayIdOriginal;

        this.stayDetailInfoHeaderTitle = this._translateService.instant('STUFF.NUMBER') + endOfString;
    }

    private _initDataSetElementData() {
        // If access param is not dataSetElementId we have to use the id returned by this request
        // (instead of the one in url because it's a stayId) to load predictions
        if (this.$state.params.access !== 'dataSetElementId') {
            this._dataSetElementId = this.dataSetElement.id;
        }

        this.isTypeRehabilitation = this._dataSetElementService.isType(this.dataSetElement, 'rehabilitation');
        this.isTypeExternal = this._dataSetElementService.isType(this.dataSetElement, 'external');

        this.canDisplayStayPredictions = !this.isTypeExternal;
        this._stayDetailService.setIsQualityControlStay(this.dataSetElement, this.isTypeRehabilitation);

        this._initStayDetailInfoHeader();
    }

    private _handleEditCodification(reload: boolean) {
        // We need to wait for dataSetElementGrouping to be updated
        // to update predictiveDiagnostics referencialPrices
        this._stayDetailService.updateDataSetElementGrouping(this._dataSetElementId,
            this.dataSetElement,
            this.isTypeRehabilitation)
            .then(() => {
                this._loadExcludingDiagnostics(reload)
                    .then(() => {
                        this._initDiagnosticsToDisplay();
                    });
                this._loadPredictiveDiagnostics(reload);
            });

        if (this.dataSetElement && this.dataSetElement.codificationDataSetElementStatus) {
            sessionStorage.setItem('hasUpdatedCodification', 'true');
        }
    }

    private _initDiagnosticsToDisplay() {
        const codificationLabelIds = [this.codificationLabels.DP, this.codificationLabels.DR, this.codificationLabels.DA, this.codificationLabels.FP, this.codificationLabels.AE];
        const diagnosticsToDisplay = {};

        codificationLabelIds.forEach(codificationLabelId => {
            diagnosticsToDisplay[codificationLabelId] = this.dataSetElement.dataSetElementDiagnostic
                .filter(diagnostic => diagnostic.codificationLabelId === codificationLabelId);

            // Verification is only done for DA
            if (codificationLabelId === this.codificationLabels.DA && !this.isTypeRehabilitation) {
                // Remove excluding diagnostics
                this._stayDetailCodificationDiagnosticService.removeDAByExclusion(
                    diagnosticsToDisplay[codificationLabelId], this.dataSetElement.excludingDiagnostics
                );
            }

            const newDP = this.dataSetElement.dataSetElementDiagnostic.find(diagnostic =>
                diagnostic.codificationLabelId === 1 && diagnostic.new
            );

            const excludedDiagnostic = this.dataSetElement.dataSetElementDiagnostic.find(diagnostic =>
                diagnostic.codificationLabelId === 3 && diagnostic.exclude
            );

            if (newDP && excludedDiagnostic) {
                this._snackbarService.success(this._translateService.instant('TOOLTIP.EXCLUDED_CODES'));
                newDP.new = false;
            }
        });
    }

    private _handleCancelNearAutomation() {
        this.isNearAutomationQualityControl = false;

        this._loadPredictiveDiagnostics(true);

        this.$state.transitionTo(this.$state.current.name, {...this.$state.params, isNearAutomationDone: 'true'});
    }

    private _setIntervalForDashboardEvent() {
        this._shouldStopSendingEvent = true;

        // Every 10 seconds
        const interval = 10000;

        this._intervalForDashboardEvent = setInterval(() => {
            if (!this._shouldStopSendingEvent) {
                const context = this._getContextForDashboardEvent();
                this._stayDetailService.sendEventForDashboard('time_spent', context, this.dataSetElement);
            }
        }, interval);
    }

    private _getContextForDashboardEvent() {
        let context = 'from_stay_list';

        if (this.$state.params.fromFilterSearchList === 'true') {
            context = 'from_rule';
        } else if (this.$state.params.fromQualityControl === 'true' ||
            this.$state.params.fromQualityControlImpreciseDiagnostic === 'true') {
            context = 'from_quality_control';
        } else if (this.$state.params.fromQualityControlNearAutomation === 'true') {
            context = 'from_near_automation_quality_control';
        } else if (this.$state.params.fromPatientDetail === 'true') {
            context = 'from_patient_detail';
        }

        return context;
    }

    private _handleTimeoutForFabButtons() {
        if (this._fabButtonsOver) {
            return;
        }

        this.showFabButtons = true;

        clearTimeout(this._timeoutForFabButtons);

        this._timeoutForFabButtons = setTimeout(() => {
            this.showFabButtons = false;
        }, 1000);
    }

    private _handleTimeoutForDashboardEvent() {
        // We clear the previous timeout on every mouse move
        // except for the first one
        if (this._timeoutForDashboardEvent) {
            clearTimeout(this._timeoutForDashboardEvent);
        }

        // If we move the mouse after the delay
        // we send the event again
        if (this._shouldStopSendingEvent === true) {
            this._shouldStopSendingEvent = false;
        }

        // Set new timeout on every mouse move
        // Stop sending after 3 minutes
        const timeout = 3 * 60 * 1000;
        this._timeoutForDashboardEvent = setTimeout(() => {
            this._shouldStopSendingEvent = true;
        }, timeout);
    }

    private _handleSessionStorage() {
        // To go back to this state when anonymization fails
        const previousState = {
            state: 'stay-detail',
            params: {...this.$state.params}
        };

        sessionStorage.setItem('previousState', JSON.stringify(previousState));
        sessionStorage.removeItem('lastStayDetailParams');

        if (this.$state.params &&
            this.$state.params.fromPatientDetail !== 'true') {
            sessionStorage.setItem('firstDataSetElementVisitedId', this.$state.params.dataSetElementId);
        }

        if (this.$state.params &&
            this.$state.params.fromPatientList !== 'true') {
            this._clearSessionStorage();
        }
    }

    private _clearSessionStorage() {
        sessionStorage.removeItem('patientListingInfo');
        sessionStorage.removeItem('currentPatientNumber');
        sessionStorage.removeItem('lastVisitedPatientId');
        sessionStorage.removeItem('lastPatientSearchParams');
    }

    private _scrollToExpansionPanel(expansionPanelName: string) {
        // In case the view takes time to be rendered we use an interval
        this._expansionPanelAvailableInterval = setInterval(() => {
            const expansionPanelElement = expansionPanelName === 'filterSearch' ? this.filterSearchExpansionPanel : this.impreciseDiagnosticExpansionPanel;

            if (expansionPanelElement && expansionPanelElement.nativeElement) {
                expansionPanelElement.nativeElement.scrollIntoView();

                clearInterval(this._expansionPanelAvailableInterval);
            }
        }, 500);

        setTimeout(() => clearInterval(this._expansionPanelAvailableInterval), 10000);
    }

    private _loadingAction(action: string) {
        if (this.$state.params.isDialog === 'true') {
            return;
        }

        action === 'start'
            ? this._layoutService.startLoading()
            : this._layoutService.stopLoading();
    }

    private async _reload() {
        const uriParams = {
            ...this.$state.params
        };

        if (typeof uriParams.fromFilterSearchList === 'undefined') {
            uriParams.fromFilterSearchList = 'false';
        } else if (uriParams.fromFilterSearchList === 'true') {
            uriParams.forFilterSearch = 'true';
        }

        await Promise.all([
            this._loadDataSetElement(),
            this._loadCodification()
        ]).then(async () => {
            try {
                await this._transitToState(uriParams);
                this._checkIsReady();
                this.isActive = true;
                this.notFound = false;
                this._loadingAction('end');
                sessionStorage.setItem('lastVisitedDataSetElementId', this.dataSetElement.parentId.toString());
                if (this.$state.params.fromQualityControl === 'true' ||
                    this.$state.params.fromFilterSearchList === 'true') {
                    this._scrollToExpansionPanel('filterSearch');
                } else if (this.$state.params.fromQualityControlImpreciseDiagnostic === 'true') {
                    this._scrollToExpansionPanel('impreciseDiagnostic');
                }

                this.activeTabId = !this.isTypeRehabilitation
                    ? this.dataSetElement.id
                    : this.$state.params.isFirstSSRWeek === 'true'
                        ? 1
                        : 2;
            } catch (e) {
                this._loadingAction('end');
                console.error(e);
            }
        });
    }

    /**
     * Need to do a transitionTo because of quality control module. We have to load first filterSearch to be
     * able to update stateParams with correct filterSearch
     * @param uriParams
     * @private
     */
    private async _transitToState(uriParams: any) {
        try {
            const currentFilterSearch = JSON.parse(sessionStorage.getItem('currentFilterSearch'));
            if (uriParams.fromQualityControl) {
                if (!uriParams.forFilterSearch || uriParams.forFilterSearch === 'false') {
                    await this._loadFiltersSearch();
                    if (this._filtersSearch?.length > 0) {
                        this._setFilterSearchReval();
                        const params = currentFilterSearch?.params || {};
                        uriParams = {
                            ...uriParams,
                            ...params,
                            fromFilterSearchList: 'false',
                            fromQualityControl: 'true',
                            forFilterSearch: 'true'
                        };
                    }
                }
            }
            DataHelperService.decodeUriParams(uriParams);
            await this.$state.transitionTo(this.$state.current.name, uriParams);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private _setFilterSearchReval() {
        const filterSearchReval = this._filtersSearch.find(filter => this._stayDetailService.getRevalCMA(this.dataSetElement, filter));
        const filterSearchToLoad = filterSearchReval || this._filtersSearch[0];
        sessionStorage.setItem('currentFilterSearch', JSON.stringify(filterSearchToLoad));
    }

    private _sendLogToLogStash() {
        const message = 'Stay codification page has been accessed';
        this._logService.logInfo(message);
    }

    private _initDataSetElementLinkAct(): void {
        if (this.dataSetElement &&
            this.dataSetElement.dataSetContent) {
            // We don't want to have grouped acts as an object but just the list of acts for some useCases
            this.dataSetElement.dataSetContent.linkActNotGrouped = this._stayDetailService.concatDataSetElementAndParentLinkActs(this.dataSetElement)?.map(el => el.act);
            this.dataSetElement.dataSetContent.linkAct = this._stayDetailService.getGroupedLinkActs(this.dataSetElement);
        }
    }

    private async _loadDataSetElement() {
        try {
            this.dataSetElement = await this._dataSetElementService
                .loadDataSetElement(this._dataSetElementId, this._codificationId);

            // If it's a parent when we throw an error to reload with the first child id
            if (this.dataSetElement && !this.dataSetElement.parentId) {
                throw new Error('This dataSetElement is a parent');
            }
            this._stayDetailService.sendEventForDashboard('stay_direct_access', null, this.dataSetElement);

            this._initDataSetElementData();
            this._initDataSetElementLinkAct();
            await this._loadAllCodificationLabels();

            await Promise.all([
                this._loadDataSetElementParent(),
                this._loadPredictiveDiagnostics(),
                this._loadPredictiveActs(),
                // TODO uncomment on dependency v2.2
                // this._loadPredictiveDependency(),
                this._loadExcludingDiagnostics()]);

            this._stayDetailService.storeFirstSSRWeekCodification(this.dataSetElementParent, this.isTypeRehabilitation);
        } catch (e) {
            if (e.message === 'This dataSetElement is a parent' &&
                this.dataSetElement.children?.length) {
                this._dataSetElementId = this.dataSetElement.children[0].id;
                await this._reload();
            } else {
                if (e.status === 404) {
                    this.isActive = true;
                    this.notFound = true;
                }
                this._loadingAction('end');
            }
            throw e;
        }
    }

    private async _loadDataSetElementParent() {
        try {
            this.dataSetElementParent = await this._dataSetElementService
                .loadDataSetElementParent(this.dataSetElement.parentId, this._codificationId);
            this.qualifactControls = this.dataSetElementParent.dataSetContent.qualifactControls ?? [];
        } catch (e) {
            throw e;
        }
    }

    private async _loadCodification() {
        try {
            this.dataSetCodification = await this._stayListService
                .loadCodification(this._dataSetId, this._codificationId);
        } catch (e) {
            throw e;
        }
    }

    private async _loadAllCodificationLabels() {
        try {
            this.codificationLabels = await this._diagnosticService
                .loadAllCodificationLabels(1, this.isTypeRehabilitation);
        } catch (e) {
            throw e;
        }
    }

    private async _loadPredictiveDiagnostics(reload = false, switchGroupByParent = false) {
        try {
            const codificationLabelIds = this.codificationLabels.getAppTypeLabels(this.isTypeRehabilitation);
            const predictiveDiagnostics =
                await this._dataSetElementService
                    .loadPredictiveDiagnostics(this.dataSetElement, codificationLabelIds, switchGroupByParent);
            if (!reload) {
                this.dataSetElement.dataSetElementDiagnosticPredictive = predictiveDiagnostics;
            } else {
                this.dataSetElement.dataSetElementDiagnosticPredictive.length = 0;
                [].push.apply(this.dataSetElement.dataSetElementDiagnosticPredictive, predictiveDiagnostics);

                // To update referencialPrice in prediction-element
                this._broadcastService.send('predictiveDiagnostic::updateReferencialPrice', {predictiveDiagnostics: this.dataSetElement.dataSetElementDiagnosticPredictive});
            }
        } catch (e) {
            throw e;
        }
    }
    public getDataSetElementDiagnosticPredictive(source: 'rule'|'ml'): any {
        return this.dataSetElement.dataSetElementDiagnosticPredictive?.filter(el => el.source === source);
    }

    public getDataSetElementActPredictive(source: 'rule'|'ml'): any {
        return this.dataSetElement.dataSetElementActPredictive?.filter(el => el.source === source);
    }
    private async _loadPredictiveActs() {
        try {
            this.dataSetElement.dataSetElementActPredictive = await this._stayListService
                .loadPredictiveActs(this.dataSetElement);
        } catch (e) {
            throw e;
        }
    }
    private async _loadPredictiveDependency() {
        try {
            if (this.isTypeRehabilitation) {
                this.dataSetElement.dataSetElementDependencyPredictive = await this._stayListService
                    .loadPredictiveDependency(this.dataSetElement);
            }
        } catch (e) {
            throw e;
        }
    }

    private async _loadExcludingDiagnostics(reload = false) {
        try {
            const dpSlug = this._stayDetailService.getMainDiagnostics(this.dataSetElement)?.diagnostic?.slug;
            if (!this.isTypeRehabilitation && dpSlug) {
                const excludingDiagnostics: any[] = await this._dataSetElementService
                    .loadDiagnostics(this._dataSetElementId,
                        dpSlug);
                const tmpexcludingDiagnostics = [];
                excludingDiagnostics.forEach(dataSetElementDiagnostic => tmpexcludingDiagnostics.push(dataSetElementDiagnostic.id));
                if (!reload) {
                    this.dataSetElement.excludingDiagnostics = tmpexcludingDiagnostics;
                } else {
                    this.dataSetElement.excludingDiagnostics = [];
                    [].push.apply(this.dataSetElement.excludingDiagnostics, tmpexcludingDiagnostics);
                    // To update prediction list
                    this._broadcastService.send('diagnosticPredictionListMenu::changeOption');
                }
            }
        } catch (e) {
            throw e;
        }
    }

    /**
     * Load filter search to select the correct one for quality control module
     * @private
     */
    private async _loadFiltersSearch() {
        try {
            const filtersSearch = await this._dataSetElementService
                .loadFilterSearchRules(this._dataSetElementParentId);
            this._filtersSearch = _.orderBy(filtersSearch, ['name'], ['asc']);
            this.dataSetElement.filtersSearch = this._filtersSearch;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async _checkIsReady() {
        try {
            if (this._configurationService.getConfigurationContent('front', 'dataSetElement.isReady')) {
                const data = await this._dataSetElementService
                    .isReady(this._dataSetElementId);
                this.dataSetElement.isReady = data ? data.status : false;
                this._broadcastService.send('dataSetElement::updateIsReady');
            }
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    private async _updateStayPrice() {
        const dataSetElement = await this._dataSetElementService
            .loadDataSetElement(this._dataSetElementId, this._codificationId);
        this._broadcastService.send('stay::updatePrice', {data: dataSetElement});
    }

    scrollToTop() {
        this._broadcastService.send('stayDetailRightSide::scrollToTop');
    }

    addDiagnostic() {
        this._stayDetailCodificationService
            .openDiagnosticAddDialog(this.dataSetElement, this.dataSetElement.dataSetElementDiagnostic, this.dataSetElement.dataSetElementDiagnosticPredictive, this.isTypeRehabilitation, this.codificationLabels);
    }

    changeFabButtonOver(over: boolean) {
        if (over) {
            clearTimeout(this._timeoutForFabButtons);
            this._fabButtonsOver = true;
            this.showFabButtons = true;
        } else {
            this._fabButtonsOver = false;
        }
    }

    toggleSideCollapse(displayState: string) {
        this[displayState] = this[displayState] === 'initial' ? 'none' : 'initial';
        // To force ngx-datatable to resize table
        // Need timeout because of the animation delay
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 600);
    }

    openMenu(event: any): void {
        if (event) {
            event.stopPropagation();
        }
        this.menuTrigger.openMenu();
    }

    canCodify(): boolean {
        return this._authenticationService.hasRole('admin');
    }

    async linkCodifyDataSetElement() {
        this.isLoading = true;
        const resp = await this._dataSetElementService
            .linkCodifyDataSetElement(this.dataSetElement.parentId);

        if (resp) {
            this._reload();
            this._broadcastService.send('filterSearch::load');
        }
        this.isLoading = false;
    }

    ngOnDestroy() {
        sessionStorage.removeItem('hasUpdatedCodification');
        sessionStorage.removeItem('firstSSRWeekCodification');
        clearInterval(this._intervalForDashboardEvent);
        if (this._subscriptions?.length > 0) {
            this._subscriptions.forEach(sub => {
                sub.unsubscribe();
            });
        }
    }
}
