import {TranslateService} from '@ngx-translate/core';
import {ApiCriteria, SearchEngineCondition} from '../search-engine.condition';
import {SearchEngineConditionDependencyComponent} from './search-engine-condition-dependency.component';
import {Injectable} from '@angular/core';
import {CodificationLabelEnum} from '../../../stay/shared/codification-label-enum';


export enum DependencyLevelEnum {
    UNASSISTED,
    THIRD_PARTY_PRESENCE,
    PARTIAL_THIRD_PARTY_ASSISTANCE,
    TOTAL_THIRD_PARTY_ASSISTANCE,
}

@Injectable()
export class SearchEngineConditionDependencyService extends SearchEngineCondition {
    // Because of shared component
    paramKey: string;
    operator = 'AND';
    placeholder: string;

    dependencyType: {key: CodificationLabelEnum , translation: string, value: string}[] = [
        {key: CodificationLabelEnum.BEHAVIOUR_DEPENDENCY, translation: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.BEHAVIOUR', value: 'behaviourDependency'},
        {key: CodificationLabelEnum.CONTINENCE_DEPENDENCY, translation: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.CONTINENCE', value: 'continenceDependency'},
        {key: CodificationLabelEnum.DRESSING_OR_TOILETING_DEPENDENCY, translation: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DRESSING', value: 'dressingOrToiletingDependency'},
        {key: CodificationLabelEnum.EATING_DEPENDENCY, translation: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.EATING', value: 'eatingDependency'},
        {key: CodificationLabelEnum.MOVING_DEPENDENCY, translation: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.MOVING', value: 'movingDependency'},
        {key: CodificationLabelEnum.RELATIONSHIP_DEPENDENCY, translation: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.RELATIONSHIP', value: 'relationshipDependency'}
        ];

    dependencyLevel: {id: number, text: string, level: DependencyLevelEnum}[] = [
        {id: 1, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.1', level: DependencyLevelEnum.UNASSISTED},
        {id: 2, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.2', level: DependencyLevelEnum.THIRD_PARTY_PRESENCE},
        {id: 3, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.3', level: DependencyLevelEnum.PARTIAL_THIRD_PARTY_ASSISTANCE},
        {id: 4, text: 'DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.4', level: DependencyLevelEnum.TOTAL_THIRD_PARTY_ASSISTANCE}
    ];


   // @ts-ignore
    constructor(private _translateService: TranslateService) {
        const field = _translateService.instant(
                `DATA_SET.CODIFICATION.SEARCH.DEPENDENCY.DEPENDENCY`
        );
        super(['dependency'], field, 'dependency', SearchEngineConditionDependencyComponent, true);
        this.init();
    }

    init(uriParams?: any, apiParams?: ApiCriteria): void {
        this.params = {[this.paramKey]: [], isValidated: false, operator: this.operator, type: ''};
        this.uriParams = {[this.paramKey]: '', isValidated: false, operator: this.operator, type: ''};
        this.setUriParams(uriParams);
        this.setApiParams(apiParams);
    }

    convertToUriParams(): void {
        if (this.params[this.paramKey]) {
            this.uriParams[this.paramKey] = this.params[this.paramKey].join(';');
        }
    }

    convertToParams(): void {
        if (this.uriParams[this.paramKey]) {
            this.params[this.paramKey] = this.uriParams[this.paramKey].split(';')?.map(id => Number.parseFloat(id));
        }
    }
    convertToParamsFromApiParams(): void {
            this.params = {
                type: this._apiParams.args.type,
                operator: this._apiParams.args.operator,
                isValidated: this._apiParams.args.isValidated,
                [this.paramKey]: this._apiParams.args.values
            };
    }

    convertToApiParams(): void {
        super.convertToApiParams();
        this.apiParams.args = {
            values: this.params[this.paramKey],
            operator: this.params.operator,
            isValidated: this.params.isValidated,
            type: this.params.type
        };
    }

    isValid(fields?: any): boolean {
        return !!(fields && !this.isUndefined(fields[this.paramKey]) &&
            !this.isEmpty(fields[this.paramKey]) && this.params[this.paramKey].length > 0);
    }
}
