<div class="flex items-center mb-2">
    <mat-radio-group
        [(ngModel)]="condition.service.params.type"
    >
        <mat-radio-button
            class="margin-right-16"
            [color]="colorService.getColorAccordingToCurrentState()"
            [value]="PredictionDiagnosticSimplifiedScoreTypeEnum.PRIMARY"
        >
            <span *ngIf="isMCOUseCase && PmsiValue === 'MCO'">{{'ACRONYM.DP' | translate}}</span>
            <span *ngIf="isMultyPmsiType && (actualPage === 'FilterSearchList' || actualPage === 'patient-detail')"> / {{'ACRONYM.MP' | translate}}</span>
            <span *ngIf="(isSSRUseCase && PmsiValue === 'SMR') || !isMCOUseCase">{{'ACRONYM.MP' | translate}}</span>
        </mat-radio-button>

        <mat-radio-button
            class="margin-right-16"
            [color]="colorService.getColorAccordingToCurrentState()"
            [value]="PredictionDiagnosticSimplifiedScoreTypeEnum.SECONDARY"
        >
            <span *ngIf="isMCOUseCase && PmsiValue === 'MCO'">{{'ACRONYM.DR' | translate}}</span>
            <span *ngIf="isMultyPmsiType && (actualPage === 'FilterSearchList' || actualPage === 'patient-detail')" > / {{'ACRONYM.AE' | translate}}</span>
            <span *ngIf="(isSSRUseCase && PmsiValue === 'SMR') || !isMCOUseCase">{{'ACRONYM.AE' | translate}}</span>

        </mat-radio-button>
        <mat-radio-button
            class="margin-right-10"
            [color]="colorService.getColorAccordingToCurrentState()"
            [value]="PredictionDiagnosticSimplifiedScoreTypeEnum.ASSOCIATED"
        >
            <span>{{ 'ACRONYM.DA' | translate}}</span>
        </mat-radio-button>
    </mat-radio-group>

    <div class="w-[1px] h-[20px] border-l border-white/20 mx-2"></div>


    <mat-form-field class="margin-right-16">
        <mat-select [placeholder]="'PREDICTION_ANALYSIS.MINIMUM_THRESHOLDS' | translate"
                    [panelClass]="['mat-' + colorService.getColorAccordingToCurrentState()]"
                    [(ngModel)]="condition.service.params.minValue">

            <!-- Définir ce qui est affiché quand le select est réduit -->
            <mat-select-trigger>
                {{ 'DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_' + getScoreKey(condition.service.params.minValue) | translate }}
            </mat-select-trigger>

            <mat-option [value]="simplifiedScoreEnum.GOLD">
                {{ 'DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_GOLD' | translate }}
                <i class="fas fa-trophy text-color md-yellow-700"></i>
            </mat-option>

            <mat-option [value]="simplifiedScoreEnum.SILVER">
                {{ 'DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_SILVER' | translate }}
                <i class="fas fa-trophy text-color md-grey-500"></i>
            </mat-option>

            <mat-option [value]="simplifiedScoreEnum.BRONZE">
                {{ 'DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_BRONZE' | translate }}
                <i class="fas fa-trophy text-color text-orange-800"></i>
            </mat-option>

            <mat-option [value]="simplifiedScoreEnum.HIGH">
                {{ 'DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_HIGH' | translate }}
                <ct-simplified-score [value]="3"></ct-simplified-score>
            </mat-option>

            <mat-option [value]="simplifiedScoreEnum.MEDIUM">
                {{ 'DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_MEDIUM' | translate }}
                <ct-simplified-score [value]="2"></ct-simplified-score>
            </mat-option>

            <mat-option [value]="simplifiedScoreEnum.LOW">
                {{ 'DATA_SET.CODIFICATION.SEARCH.PREDICTIVE_LOW' | translate }}
                <ct-simplified-score [value]="1"></ct-simplified-score>
            </mat-option>

        </mat-select>
    </mat-form-field>
</div>
