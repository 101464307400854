export interface Diagnostic {
    id?: number;
    slug?: string;
    name?: string;
    description?: string;
    diagnosisChronic?: number;
    status?: boolean;
    codifiable?: boolean;
    size?: number;
    parentId?: number;
    diagnosticLevel?: any;
    validFrom?: string;
    validTo?: string;
}

export enum SimplifiedScoreEnum {
    GOLD = 40,
    SILVER = 30,
    BRONZE = 20,
    HIGH = 3,
    MEDIUM = 2,
    LOW = 1,
    VERY_LOW = 0,
}
